<template>
  <v-data-table
    must-sort
    fixed-header
    :loading="loading"
    :headers="headers"
    :items="diagnoses"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 15, 25, 50 ],
      itemsPerPageText: `Diagnosis codes ${ $t('per page') }:`,
      showFirstLastPage: true
    }"
    class="elevation-2"
  >
    <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col md="2">
          <table-filter
            :value.sync="options.filter.group"
            :choices="groups"
            label="Group"
            icon="fal fa-folder"
            item-value="name"
            item-text="name"
            multiple
          />
        </v-col>
      </v-row>
    </template>
    <template #item.icd_10_code="{ item }">
      <diagnosis-chip
        :diagnosis="item.diagnosis_code"
        :category="item.category_code"
        :icd10="item.icd_10_code"
        :text="item.abbreviated"
        :group="item.group_id"
        no-edit
      />
    </template>
    <template #item.group="{ item }">
      <v-chip
        v-if="item.group"
        small
        v-text="item.group.name"
      />
    </template>
    <template #item.sources="{ item }">
      <source-icon
        v-for="source in item.group?.relevant_sources"
        :key="source.id"
        :source="source"
      />
    </template>
    <template #item.indicates_pregnancy="{ item }">
      <v-icon
        v-if="item.indicates_pregnancy"
        color="pink darken-1"
      >
        fas fa-fw fa-person-pregnant
      </v-icon>
    </template>
    <template #item.lab_results_count="{ item }">
      <router-link :to="{ name: 'Lab Results', query: { diagnosis: item.icd_10_code } }">
        {{ item.lab_results_count }}
      </router-link>
    </template>
    <template
      #item.actions="{ item }"
    >
      <v-btn
        small
        ripple
        icon
        plain
        @click="$emit('toggle-right-drawer', item, true)"
      >
        <v-icon>fal fa-ellipsis-v</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
  import Vue2Filters from 'vue2-filters'

  export default {
    components: {
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      TableFilter: () => import('@/components/tables/filters/TableFilter.vue'),
      DiagnosisChip: () => import('@/components/DiagnosisChip.vue'),
      SourceIcon: () => import('@/components/source/SourceIcon.vue'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    props: {
      search: {
        type: String,
        default: null,
      },
    },
    metaInfo: {
      title: 'Diagnosis Codes',
    },
    data () {
      return {
        loading: false,
        options: {
          filter: this.$route.query,
          search: null,
          sortBy: ['icd_10_code'],
          sortDesc: [false],
        },
        detailDialog: {},
        totalItems: null,
        headers: [
          {
            text: 'Code',
            value: 'icd_10_code',
          },
          {
            text: 'Description',
            value: 'description',
          },
          {
            text: 'Group',
            value: 'group',
            sortable: false,
          },
          {
            text: 'Relevant Sources',
            value: 'sources',
            sortable: false,
          },
          {
            text: 'Indicates Pregnancy',
            value: 'indicates_pregnancy',
          },
          {
            text: 'Lab Results',
            value: 'lab_results_count',
            sortable: false,
          },
          {
            sortable: false,
            align: 'right',
            value: 'actions',
          },
        ],
        diagnoses: [],
        groups: [],
      }
    },
    watch: {

      options: {
        deep: true,
        handler () {
          this.fetchDiagnoses()
        },
      },
    },
    mounted () {
      this.axios.get('admin/diagnoses/groups')
        .then((response) => {
          this.groups = response.data
        }).catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        })
    },
    methods: {
      fetchDiagnoses () {
        this.loading = true
        this.axios.get('admin/diagnoses', {
          params: {
            ...{
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? "desc" : "asc",
              search: this.options.search,
            },
            ...this.$route.query,
          },
        }).then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchDiagnoses()
            return
          }
          this.$set(this.options, 'page', response.data.current_page)
          this.$set(this.options, 'itemsPerPage', Number(response.data.per_page))
          this.totalItems = response.data.total
          this.diagnoses = response.data.data
        }).catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        }).finally(() => {
          this.loading = false
        })
      },
      detailModal (title, data) {
        this.detailDialog = {
          open: true,
          title: title,
          id: data.id,
        }
      },
      colorGauge (weight) {
        var color1 = weight > 50 ? [0, 204, 0] : [255, 165, 0]
        var color2 = weight > 50 ? [255, 165, 0] : [255, 0, 0]
        var p = weight > 50 ? (weight - 50) / 50 : weight / 50
        var w = p * 2 - 1
        var w1 = (w / 1 + 1) / 2
        var w2 = 1 - w1
        var rgb = [
          Math.round(color1[0] * w1 + color2[0] * w2),
          Math.round(color1[1] * w1 + color2[1] * w2),
          Math.round(color1[2] * w1 + color2[2] * w2),
        ]
        return 'rgb(' + rgb.join() + ')'
      },
    },
  }
</script>
<style lang="scss" scoped>
.inner-text {
  height: 100%;
  width: 100%;
  font-size: 4em;
  padding-top: 1.1em;

  span {
    max-width: 100px;
  }
}
</style>
